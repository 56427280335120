<template>
  <div>
    <!-- 药品收费banner -->
    <page-banner :info="bannerInfo"></page-banner>
    <router-view></router-view>
  </div>
</template>

<script>
/****************
 *@author: niubj
 *@date: 2021-11-24 14:41:03
 *@version: V1.0.0
 *@description: 药品信息(收费)
 *************************************************************************/
import PageBanner from "@/components/PageBanner.vue";
export default {
  name: "Production",
  components: { PageBanner,  },
  props: {},
  data() {
    return {
      bannerInfo: {
        title: "All products",
        subtitle: "药品信息(收费)",
        bgClass: "products-bg",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style lang="less" scoped></style>
